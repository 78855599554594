import { EPMC_API_GET_ROOT } from '@/config'
import { send } from '@/api'

export function fetchJournals(term) {
  const req = EPMC_API_GET_ROOT + 'journalApi?term=' + term
  return send(req)
}

export function fetchAuthors(term) {
  const req = EPMC_API_GET_ROOT + 'authorOrOrcidApi?term=' + term
  return send(req)
}

export function fetchFundingAgencies(term) {
  const req = EPMC_API_GET_ROOT + 'fundingAgencyApi?term=' + term
  return send(req)
}

export function fetchGrantIds(term) {
  const req = EPMC_API_GET_ROOT + 'grantIdApi?term=' + term
  return send(req)
}
